import { graphql, StaticQuery } from "gatsby"
import React from "react"
import ReactWOW from 'react-wow';
import NewsFilter from "./NewsFilter"

export default class News extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        query: props
    }
  }
  componentDidMount() {
    // var _this = this;
    if (typeof window !== `undefined`) {
      // import Isotope API
      const Isotope = require("isotope-layout/js/isotope")

      // init Isotope
      this.iso = new Isotope(`#notes_filter`, {
        itemSelector: `.item`,
      })
    }
  }

  render() {
    const newsList = []
    if (this.state.query.data.prismic.allNewss.edges != null) {
      if (this.state.query.data.prismic.allNewss.edges.length > 0) {
        // sort news
        this.state.query.data.prismic.allNewss.edges.sort((a, b) => {
          return new Date(b.node.date) - new Date(a.node.date)
        })
        for (let i = 0; i < this.state.query.data.prismic.allNewss.edges.length; i++) {
          const news = this.state.query.data.prismic.allNewss.edges[i]
          const labels = []
          let classNames = ""
          if (news.node.categories.length > 0) {
            for (let j = 0; j < news.node.categories.length; j++) {
              const category = news.node.categories[j]
              let categoryClass = ""
              switch (category.category.toLowerCase()) {
                case "update":
                  categoryClass = "up"
                  break
                case "feature":
                  categoryClass = "feat"
                  break
                case "bug":
                  categoryClass = "bug"
                  break
                default:
                  break
              }
              classNames = classNames + " " + categoryClass
              labels.push(
                <div
                  key={category.category + j.toString()}
                  className={`notes-label ${category.category.toLowerCase()}`}
                >
                  {category.category}
                </div>
              )
            }
          }

          let title = ""
          if (news.node.title != null) {
            title = <h3 className="h_head">{news.node.title[0].text}</h3>
          }

          let contentList = []
          if (news.node.content.length > 0) {
            for (let i = 0; i < news.node.content.length; i++) {
              const content = news.node.content[i]
              if(content.type == 'paragraph')
                contentList.push(<p>{content.text}</p>)
              if(content.type == 'list-item'){
                contentList.push(
                  <ul style={{paddingLeft: '24px'}}>
                    <li>{content.text}</li>
                  </ul>
                )
              }
            }
          }
          newsList.push(
            <div key={i} className={`media item${classNames}`}>
              <div className="media-left">
                <div className="notes-date">{news.node.date}</div>
                {labels}
              </div>
              <div className="media-body">
                {title}
                {contentList}
              </div>
            </div>
          )
        }
      }
    }
    return (
      <section className="event_schedule_area sec_pad">
        <div className="container">
          <div className="hosting_title security_title text-center">
            <ReactWOW animation="fadeInUp" delay="0.2s">
              <h2>
                <span>Release Notes</span>
              </h2>
            </ReactWOW>
            <p className="f_400 f_size_16 l_height30 mb_20">
              Don't be fooled by our low cost and <i>forever free</i> version -
              <br />
              our talented team of gurus are continuously crunching code and
              aligning pixels.
            </p>
          </div>
          <div className="event_schedule_inner job_listing">
            <NewsFilter />
            <div className="event_tab_content" id="notes_filter">
              {newsList}
            </div>
          </div>
        </div>
      </section>
    )
  }
}
